<template>
    <el-container>
        <el-header class="header">
            <router-link to="/main" class="logo hidden-md-and-down">
                <img src="../../../assets/mlogo.png" alt="logo">
                {{ title }}
            </router-link>
            <div class="hidden-lg-and-up">
                <el-button icon="el-icon-menu" style="color:white;font-size:110%" type="text"
                    @click="drawer = true"></el-button>
            </div>
            <div class="head">
            </div>
            <div class="user">
                <el-dropdown trigger="click">
                    <span class="username">
                        {{ $store.getters.user.name }}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <router-link class="link" to="/main">
                            <el-dropdown-item>首页</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/main/password">
                            <el-dropdown-item>修改密码</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/logout">
                            <el-dropdown-item divided>退出登陆</el-dropdown-item>
                        </router-link>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-header>
        <el-container>
            <slot></slot>
        </el-container>
        <el-drawer title="菜单" :visible.sync="drawer" direction="ltr" widht="300px">
            <div style="min-width: 300px">
                <span>手机端的菜单</span>
            </div>
        </el-drawer>
    </el-container>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '佐相科技'
        }
    },
    data() {
        return {
            drawer: false
        }
    },
    methods: {
        doLogout() {
            this.$router.push({ path: '/' })
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        }
    },
    mounted() {
        this.$http.get('/user/profile')
            .then(resp => {
                this.$store.commit('set_user', resp.data)
            }).catch(err => {
                this.$message.error(err.message)
            })
    }
}
</script>

<style lang="scss" scoped>
.header {
    background: #2081c0;
    color: #fff;
    display: flex;
    flex-direction: row;
    height: 60px;
    line-height: 60px;

    .logo {
        width: 240px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        color: #ffffff;
        text-decoration: none;

        img {
            width: 35px;
            height: 35px;
            border-radius: 5px;
            margin-right: 10px;
        }
    }

    .head {
        flex: 1;
    }

    .user {
        text-align: right;
        width: 180px;

        .username {
            color: #ffffff;
            cursor: pointer;
            vertical-align: middle;
            display: flex;
            align-items: center;

            .user-avatar {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.link {
    text-decoration: none;
}
</style>
